import React, { useEffect, useMemo, useState } from 'react'
import { NextSeo } from 'next-seo'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import styled from 'styled-components'
import useIsMobile from 'hooks/util/useIsMobile'
import { BackgroundGrid } from 'components/core/BackgroundGrid'
import { HeaderSpacer } from 'components/core/header/Header'
import { ContentWrapper } from 'components/core/layout/Common'
import { PageView } from 'components/core/layout/FeaturedItemPage'
import { Spacer, VerticalSpacer } from 'components/core/Spacer'
import EquationCross from 'components/core/icons/factory/EquationCross'
import EquationEquals from 'components/core/icons/factory/EquationEquals'
import EquationRoot from 'components/core/icons/factory/EquationRoot'
import EquationRootTail from 'components/core/icons/factory/EquationRootTail'
import { cdnRoot } from 'utils/cdn'
import { IconButton, PrimaryButton } from 'components/core/Buttons'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import dynamic from 'next/dynamic'
import {
  RedoIcon,
  BrushIcon,
  EraserIcon,
  HandIcon,
  PaintBucketIcon,
  PlayIcon,
  TextIcon,
  UndoIcon,
} from 'components/draw/styles'
import KonvaPlayback from 'components/draw/components/KonvaPlayback'

import Image from 'next/image'
import useWindowSize from 'components/draw/hooks/useWindowSize'

import Zoom from 'react-medium-image-zoom'
import { GobblersImg } from 'components/image/Image'

// import { RowAlignedCenter } from './layout/Common'
// import { ExternalLink } from './Links'

import { TwitterTweetIcon } from 'components/draw/styles'
import { H3Responsive, H4Responsive, P } from 'components/core/Typography'
import { Footer } from 'components/core/Footer'
import { ExternalLink } from 'components/core/Links'
import { useTrail, config, animated } from '@react-spring/web'
import { useIsMounted } from 'hooks/util/useIsMounted'
import Link from 'next/link'
import { HomeScripts } from 'components/home/HomeScripts'
// import styled from 'styled-components'
import GooPlaceholderEquation from 'components/factory/GooPlaceholderEquation'
import GooPlaceholderEquationHome from 'components/factory/GooPlaceholderEquationHome'
import { trpc } from '~/utils/trpc'
import { getDisplayNameFromGobblerAuthAccount } from 'utils/auth'
import { DEFAULT_ARTWORK_TITLE } from 'components/draw/constants'

const black = '#080A0C'
const DEFAULT_IMAGE_CONTAINER_WIDTH = 303
const DEFAULT_IMAGE_CONTAINER_HEIGHT = 420

const VideoPlayer = dynamic(import('../../components/core/MainVideo'), {
  ssr: false,
})

const MobileVideoPlayer = dynamic(import('../../components/core/MobileVideo'), {
  ssr: false,
})

export default function AboutPage() {
  const { width, height } = useWindowSize()

  const [hasLoadedVideo, setHasLoadedVideo] = useState<boolean>(false)
  const [recentImagesLoaded, setRecentImagesLoaded] = useState<Array<number>>([])

  const isMobile = useIsMobile()

  const mounted = useIsMounted()

  const trail = useTrail(13, {
    config: config.gentle,
    opacity: mounted ? 1 : 0,
    y: mounted ? 0 : 8,
    from: { opacity: 0, y: 8 },
  })

  const headerOptions = useMemo(
    () => [
      {
        active: true,
        Icon: BrushIcon,
      },
      {
        Icon: PaintBucketIcon,
      },
      {
        Icon: EraserIcon,
      },

      {
        Icon: TextIcon,
      },
      {
        Icon: HandIcon,
      },
      {
        Icon: UndoIcon,
      },
      {
        Icon: RedoIcon,
      },
    ],
    [],
  )

  const recentlyGlaminatedPagesQuery = trpc.useQuery([
    'page.recentlyGlaminated',
    { take: 4, filterNsfw: true },
  ])

  const recentlyGlaminatedPages = useMemo(() => {
    if (recentlyGlaminatedPagesQuery.data) {
      return recentlyGlaminatedPagesQuery.data
    }
    return []
  }, [recentlyGlaminatedPagesQuery.data])

  const smallScreen = width < 600

  return (
    <>
      <link
        rel="preload"
        href="/fonts/matter/Matter-Heavy.woff2"
        as="font"
        crossOrigin=""
        type="font/woff2"
      />
      <link
        rel="preload"
        href="/fonts/matter/Matter-Bold.woff2"
        as="font"
        crossOrigin=""
        type="font/woff2"
      />
      <link
        rel="preload"
        href="/fonts/matter/Matter-Regular.woff2"
        as="font"
        crossOrigin=""
        type="font/woff2"
      />
      <link
        rel="preload"
        href="/fonts/matter/Matter-SemiBold.woff2"
        as="font"
        crossOrigin=""
        type="font/woff2"
      />
      {/* <link rel="preload" as="image" href={`${cdnRoot}/images/logo-simple.png`} /> */}
      {/* <link rel="preload" as="image" href={`${cdnRoot}/images/about-bg-1.png`} /> */}
      {/* <link rel="preload" as="image" href={`${cdnRoot}/images/about-bg-2.png`} /> */}
      {/* <link rel="preload" as="image" href={`${cdnRoot}/images/gobbler-invite-full.png`} /> */}
      {/* <link rel="preload" as="image" href={`/animations/splash-page/images/_preloader.gif`} /> */}
      <link
        rel="preload"
        as="video"
        type="video/mp4"
        href={`${cdnRoot}/videos/art-gobblers-main.mp4`}
      />

      {/* <HomeScripts /> */}

      <PageView
        style={{
          background: '#F3FF8D',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {/* {isMobile ? null : <BackgroundGrid />} */}

        <div
          style={{
            position: 'relative',
            width: '100vw',
            top: 0,
            height: width < 600 ? 600 : 'calc(100vw / 1.346)',
          }}
        >
          <Skeleton
            width={'100%'}
            height={'100%'}
            baseColor={'#ebebeb'}
            style={{
              opacity: hasLoadedVideo ? 0 : 1,
              transition: 'opacity 300ms ease 0ms',
              position: 'absolute',
              zIndex: 100,
            }}
          />
          <div style={{ width: '100%', height: '100%', position: 'relative', top: -19 }}>
            {width < 600 ? (
              <MobileVideoPlayer
                hasLoadedVideo={hasLoadedVideo}
                setHasLoadedVideo={setHasLoadedVideo}
              ></MobileVideoPlayer>
            ) : (
              <VideoPlayer
                hasLoadedVideo={hasLoadedVideo}
                setHasLoadedVideo={setHasLoadedVideo}
              ></VideoPlayer>
            )}
          </div>

          <div
            style={{
              position: 'absolute',

              left: 0,
              right: 0,
              bottom: -20,
              height: 40,
              // border: '10px solid red',
              zIndex: 0,
              background: 'linear-gradient(180deg, rgba(244, 246, 82, 1) 0%, #F5FF9C 100%)',
            }}
          ></div>
        </div>

        {/* <div
          id="splash-page-animation-container"
          style={{
            backgroundColor: 'transparent',
            width: 1400,
            height: 1400,
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 10,
              background: 'linear-gradient(180deg, rgba(235, 217, 135, 0) 20%, #EBF787 100%)',
              bottom: 0,
              left: 0,
              right: 0,
              height: 120,
              // border: '5px solid red',
            }}
          ></div>
          <canvas
            id="splash-page-canvas"
            width="1400"
            height="1400"
            style={{
              position: 'absolute',
              display: 'none',
              backgroundColor: 'transparent',
            }}
          ></canvas>
          <div
            id="splash-page-dom-overlay-container"
            style={{
              pointerEvents: 'none',
              overflow: 'hidden',
              width: 1400,
              height: 1400,
              position: 'absolute',
              left: 0,
              top: 0,
              display: 'none',
            }}
          ></div>
        </div>
        <div
          id="splash-animation-preloader"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'inline-block',
            height: 1400,
            width: 1400,
            textAlign: 'center',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              maxHeight: '100%',
            }}
          ></span>{' '}
          <img
            src={'/animations/splash-page/images/_preloader.gif'}
            style={{
              verticalAlign: 'middle',
              maxHeight: '100%',
            }}
          />
        </div> */}

        <ContentWrapper
          style={{
            color: black,
            zIndex: 1,
            position: 'relative',
            minHeight: '100vh',
            maxWidth: 1338,
            display: 'flex',
            flexDirection: 'column',
            transform: 'translate3d(0,0,0)',
          }}
        >
          {/* <Gradient1></Gradient1> */}

          <div style={{ position: 'relative', zIndex: 1 }}>
            {/* <HeaderSpacer /> */}
            {width > 1000 && <Spacer size={60} />}

            {/* <animated.div style={trail[0]}>
              <MobileLogoContainer>
                <MobileLogoImage
                  objectFit="cover"
                  layout="fill"
                  src={`${cdnRoot}/images/mobile-home-title.png`}
                ></MobileLogoImage>
              </MobileLogoContainer>
            </animated.div> */}

            <Spacer size={24} />
            <AboutMain>
              <animated.div style={trail[0]}>
                <IntroTitle>An experimental decentralized art factory</IntroTitle>
              </animated.div>
              <Spacer size={24} />
              <animated.div style={trail[0]}>
                <AboutTitle>{'The invasion has begun!'}</AboutTitle>
                <AboutTitle>{'Feed the Art Gobblers your art and'}</AboutTitle>
                <AboutTitle>{'help them cover the world in Goo'}</AboutTitle>
              </animated.div>
              <Spacer size={56} />

              <animated.div>
                <Link
                  passHref={true}
                  href={{
                    pathname: '/gobbler/mint',
                  }}
                >
                  <PrimaryButton
                    style={{
                      width: 290,
                    }}
                    as={'a'}
                    buttonType={'success'}
                  >
                    Mint your Gobbler
                  </PrimaryButton>
                </Link>
              </animated.div>
              <Spacer size={48} />

              <animated.div style={trail[0]}>
                <AboutSubtitle>An experiment by</AboutSubtitle>
                <AboutSubtitle>
                  <b>J. Roiland</b> and <b>Paradigm</b>
                </AboutSubtitle>
              </animated.div>
              <Spacer size={16} />

              <animated.div style={{ position: 'relative', width: 400, height: 120, ...trail[3] }}>
                <Image
                  src={`${cdnRoot}/images/gobbler-circles.png`}
                  objectFit={'contain'}
                  layout={'fill'}
                />
              </animated.div>
            </AboutMain>

            <Spacer size={32} />

            <animated.div style={trail[1]}>
              <RecentlySoldContainer>
                <RecentlySoldTop>
                  <RecentlySoldTitle>Recently Glaminated</RecentlySoldTitle>
                  {/* <Link
                    passHref={true}
                    href={{
                      pathname: '/',
                    }}
                  >
                    <RecentlySoldAllBlock>
                      <RecentlySoldSeeAll>See all</RecentlySoldSeeAll>
                      <VerticalSpacer size={12} />
                      <SeeAllButton>
                        <SeeAllButtonIcon></SeeAllButtonIcon>
                      </SeeAllButton>
                    </RecentlySoldAllBlock>
                  </Link> */}
                </RecentlySoldTop>

                <RecentlySoldRow>
                  {recentlyGlaminatedPages.map((page, imageIndex) => {
                    const artworkTitle = page.drawing.artwork_title
                    const maxTitleLength = 20
                    let shortenedTitle = artworkTitle.substring(0, maxTitleLength)
                    if (artworkTitle.length > maxTitleLength) shortenedTitle += '...'
                    return (
                      <Link key={page.id} passHref={true} href={`/page/${page.id}` as any}>
                        <ArtworkBox as={'a'} key={page.id}>
                          <ArtworkBoxImageOuter>
                            {recentImagesLoaded.includes(imageIndex) && (
                              <Skeleton
                                width={DEFAULT_IMAGE_CONTAINER_WIDTH}
                                height={DEFAULT_IMAGE_CONTAINER_WIDTH}
                                borderRadius={30}
                                style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                              />
                            )}
                            <ArtworkBoxImage
                              onLoadedData={() =>
                                setRecentImagesLoaded([...recentImagesLoaded, imageIndex])
                              }
                              layout={'fill'}
                              objectFit={'contain'}
                              src={page.cdn_image_url}
                            ></ArtworkBoxImage>
                          </ArtworkBoxImageOuter>
                          <ArtworkBoxBottom>
                            <ArtworkBoxTitle>
                              {shortenedTitle || DEFAULT_ARTWORK_TITLE}
                            </ArtworkBoxTitle>
                            <Spacer size={12} />
                            <ArtworkBoxUser>
                              <ArtworkBoxUserImage
                                width={32}
                                height={32}
                                src={`${cdnRoot}/images/user-avatar.png`}
                              ></ArtworkBoxUserImage>
                              <VerticalSpacer size={12} />
                              <ArtworkBoxUsername>
                                {getDisplayNameFromGobblerAuthAccount(page.drawing.author, true)}
                              </ArtworkBoxUsername>
                            </ArtworkBoxUser>
                          </ArtworkBoxBottom>
                        </ArtworkBox>
                      </Link>
                    )
                  })}
                </RecentlySoldRow>
              </RecentlySoldContainer>

              <Spacer size={80} />
            </animated.div>

            <animated.div style={trail[1]}>
              <JoinConversationText>{'Find your place in the new order'}</JoinConversationText>
            </animated.div>

            <Spacer size={64} />

            {/* <GobblerBackgroundContainer>
              <animated.div
                style={{
                  zIndex: 2,
                  ...trail[3],
                }}
              >
                <GobblerBackground1>
                  <Image
                    quality={100}
                    priority={true}
                    objectFit="contain"
                    layout="fill"
                    src={`${cdnRoot}/images/about-bg-1.png`}
                  />
                </GobblerBackground1>
              </animated.div>
              <animated.div style={{ zIndex: 2, ...trail[3] }}>
                <GobblerBackground2>
                  <Image
                    quality={100}
                    priority={true}
                    objectFit="contain"
                    layout="fill"
                    src={`${cdnRoot}/images/about-bg-2.png`}
                  />
                </GobblerBackground2>
              </animated.div>
            </GobblerBackgroundContainer> */}

            {/* <Gradient2></Gradient2> */}

            <AboutWhiteBox>
              {/* <GooSplatSvg2></GooSplatSvg2>
                <GooSplatSvg3></GooSplatSvg3> */}
              <AboutBoxSplatContainer1>
                <GooSplatSvg1></GooSplatSvg1>
              </AboutBoxSplatContainer1>

              <AboutBoxSplatContainer2>
                <GooSplatSvg2></GooSplatSvg2>
              </AboutBoxSplatContainer2>

              <AboutBoxSplatContainer3>
                <GooSplatSvg3></GooSplatSvg3>
              </AboutBoxSplatContainer3>

              <WhiteBoxLeft>
                <WhiteBoxTitle>{'Make Art. Sell Art.'}</WhiteBoxTitle>
                <Spacer size={12} />
                <WhiteBoxSubtitle>
                  {'Make art with our draw tool and share it with the world for free.'}
                </WhiteBoxSubtitle>
                <Spacer size={36} />
                <WhiteBoxSubtitle>
                  {
                    'Glaminate it with a Blank Page to turn it into a rare and gobble-able digital collectible.'
                  }
                </WhiteBoxSubtitle>

                <Spacer size={24} />
                <Link
                  passHref
                  href={{
                    pathname: '/draw',
                  }}
                >
                  <PrimaryButton
                    as={'a'}
                    buttonTextStyles={{
                      color: '#8EF42E',
                      fontWeight: 700,
                      fontSize: smallScreen ? 20 : 24,
                    }}
                    style={{
                      width: smallScreen ? 240 : 270,
                      height: smallScreen ? 56 : 72,
                    }}
                  >
                    Start Drawing
                  </PrimaryButton>
                </Link>
              </WhiteBoxLeft>

              <WhiteBoxCheckerboard>
                <WhiteBoxCheckerboardImageContainer>
                  <Image
                    priority={true}
                    objectFit="contain"
                    layout="fill"
                    src={`${cdnRoot}/images/default-bg-800.png`}
                    style={{ borderRadius: 20, backgroundColor: 'white' }}
                  />
                </WhiteBoxCheckerboardImageContainer>
              </WhiteBoxCheckerboard>

              <WhiteBoxGobbler>
                <WhiteBoxGobblerInner>
                  <Image
                    quality={100}
                    priority={true}
                    objectFit="contain"
                    layout="fill"
                    src={`${cdnRoot}/images/gobbler-invite-full.png`}
                  />
                </WhiteBoxGobblerInner>
              </WhiteBoxGobbler>

              {/** John - Formula is dynamic so you can scale easily, not sure how to set this up cleanly */}
              <WhiteBoxButtons>
                {headerOptions.map((option, i) => {
                  const downsize = width < 600
                  const downsizeMult = downsize ? 0.6 : 1
                  const Icon = option.Icon
                  return (
                    <animated.div
                      key={i}
                      style={{
                        ...trail[i + 6],
                        position: 'absolute',
                        marginTop:
                          i === 0 || i === 6
                            ? 152 * downsizeMult
                            : i === 1 || i === 5
                            ? 72 * downsizeMult
                            : i === 2 || i === 4
                            ? 16 * downsizeMult
                            : 0,
                        marginLeft:
                          i === 0
                            ? 0
                            : i === 1
                            ? 64 * downsizeMult
                            : i === 2
                            ? 152 * downsizeMult
                            : i === 3
                            ? 255 * downsizeMult
                            : i === 4
                            ? (255 + (255 - 152)) * downsizeMult // distance
                            : i === 5
                            ? (357 + (152 - 64)) * downsizeMult
                            : i === 6
                            ? (255 + 255) * downsizeMult
                            : 0,
                      }}
                    >
                      <IconButton
                        size={86 * downsizeMult}
                        isDisabled={false}
                        active={option.active}
                        onPress={() => null}
                      >
                        {Icon && (
                          <Icon
                            width={36 * downsizeMult}
                            height={36 * downsizeMult}
                            active={option.active || false}
                          />
                        )}
                      </IconButton>
                    </animated.div>
                  )
                })}
              </WhiteBoxButtons>
            </AboutWhiteBox>

            <Spacer size={40} />

            <SummonGobblersBox>
              <SummonGobblersTop>
                <SummonGobblersTitle>Summon Gobblers</SummonGobblersTitle>
                <Spacer size={12} />
                <SummonGobblersSubtitle>
                  {'Use Goo to teleport new Art Gobblers down to Earth'}
                </SummonGobblersSubtitle>

                <Spacer size={24} />
                <Link
                  passHref
                  href={{
                    pathname: '/gobbler/mint',
                  }}
                >
                  <PrimaryButton
                    as={'a'}
                    buttonTextStyles={{
                      color: '#8EF42E',
                      fontWeight: 700,
                      fontSize: smallScreen ? 20 : 24,
                    }}
                    style={{
                      width: smallScreen ? 240 : 270,
                      height: smallScreen ? 56 : 72,
                    }}
                  >
                    Mint your Gobbler
                  </PrimaryButton>
                </Link>
              </SummonGobblersTop>
              <SummonGobblersBottom>
                <SummonGobblersGradient />
                <SummonGobblersBottomImageContainer>
                  <SummonGobblersBottomImage
                    objectFit="cover"
                    layout="fill"
                    src={
                      width < 500
                        ? `${cdnRoot}/images/summon-mobile.png`
                        : `${cdnRoot}/images/summon-bg-1.5.png`
                    }
                  />
                </SummonGobblersBottomImageContainer>
              </SummonGobblersBottom>
            </SummonGobblersBox>

            <Spacer size={36} />

            <BoxRow1>
              <ProduceGooBox>
                <ProduceGooTitle>Stack Goo</ProduceGooTitle>
                <Spacer size={12} />
                <ProduceGooSubtitle>
                  Gobblers produce Goo to collect.
                  <br />
                  The more Goo you hold, the faster it produces.
                </ProduceGooSubtitle>
                <ProduceGooSubtitle></ProduceGooSubtitle>
                <Spacer size={24} />

                <GooProductionContiainer>
                  <GooProductionContainerTop>Goo Production Rate</GooProductionContainerTop>
                  <GooProductionContainerBottom>
                    <GooPlaceholderEquationHome small={width <= 1000}></GooPlaceholderEquationHome>
                  </GooProductionContainerBottom>
                </GooProductionContiainer>
              </ProduceGooBox>
              <CollectArtBox>
                <CollectArtTop>
                  <CollectArtImageBody>
                    <CollectArtImage
                      objectFit="contain"
                      layout="fill"
                      src={`${cdnRoot}/images/gobbler-draw-bottom-1.png`}
                    ></CollectArtImage>
                  </CollectArtImageBody>
                </CollectArtTop>

                <CollectArtTitle style={{ marginTop: -64 }}>Gobble Art</CollectArtTitle>
                <Spacer size={16} />

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <CollectArtSubtitle style={{ maxWidth: 580 }}>
                    Every Art Gobbler wants to gobble the coolest art they can find.
                    <br />
                    It is their greatest pleasure, and the ultimate mark of status.
                  </CollectArtSubtitle>
                  <Spacer size={16} />
                  <CollectArtSubtitle style={{ maxWidth: 580 }}>
                    When your Art Gobbler gobbles art glaminated onto an Art Gobblers page, that art
                    is displayed in its belly gallery forever.
                  </CollectArtSubtitle>
                </div>
              </CollectArtBox>
            </BoxRow1>

            <Spacer size={36} />
            <BoxRow2>
              <SummonLeaderBox>
                <div>
                  {/* <SummonLeaderImageContainer> */}
                  <SummonLeaderImage
                    style={{ transform: 'translateY(64px)' }}
                    objectFit="contain"
                    layout="fill"
                    src={`https://storage.googleapis.com/gobblers.artgobblers.com/gifs/9991.gif`}
                  ></SummonLeaderImage>
                </div>
                {/* </SummonLeaderImageContainer> */}
                {/* <Spacer size={32} /> */}
                {/* <SummonLeaderTop>
                  <SummonLeaderImageContainer>
                    <SummonLeaderImage
                      objectFit="contain"
                      layout="fill"
                      src={`https://storage.googleapis.com/gobblers.artgobblers.com/gifs/9991.gif`}
                    ></SummonLeaderImage>
                  </SummonLeaderImageContainer>
                </SummonLeaderTop> */}

                <div style={{ position: 'absolute', zIndex: 2, top: 0, left: 0, right: 0 }}>
                  <Spacer size={32} />
                  <SummonLeaderText>
                    <SummonLeaderTitle>Legendary Gobblers</SummonLeaderTitle>
                    <Spacer size={16} />
                    <CollectArtSubtitle>
                      Sacrifice your Art Gobblers to summon a Legendary Gobbler!
                    </CollectArtSubtitle>
                    <Spacer size={6} />
                    <CollectArtSubtitle>Not for the faint of heart.</CollectArtSubtitle>
                  </SummonLeaderText>
                </div>
              </SummonLeaderBox>
              <GithubBox>
                <GithubBoxBackgroundTopSvg />
                <GithubBoxBackgroundBottomSvg />

                <GithubBoxTitle>{`Build on top`}</GithubBoxTitle>
                <Spacer size={16} />
                <GithubBoxSubtitle>
                  Our contracts are open source and designed to be maximally composable. Build
                  something amazing, and win the acclaim of the community!
                </GithubBoxSubtitle>
                {/* <Spacer size={6} />
                <GithubBoxSubtitle>and see what you can innovate with!</GithubBoxSubtitle> */}

                <Spacer size={32} />
                <PrimaryButton
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                  as={'a'}
                  href={'https://github.com/artgobblers/art-gobblers'}
                  style={{
                    width: smallScreen ? 270 : 300,
                  }}
                  buttonTextStyles={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: 24,
                  }}
                >
                  <GithubIcon />
                  <VerticalSpacer size={6} />
                  <div>{'View on Github'}</div>
                </PrimaryButton>
                {/* <Spacer size={24} /> */}
                {/* <GithubDocsText>Read docs</GithubDocsText> */}
              </GithubBox>
            </BoxRow2>
          </div>
          <Spacer size={160} />
        </ContentWrapper>
        <Footer />
      </PageView>
    </>
  )
}

const MobileLogoContainer = styled.div`
  position: relative;
  width: 333px;
  height: 207px;
`

const MobileLogoImage = styled(Image)``

const AboutMain = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 24px;
    justify-items: center;
    justify-content: center;
  }
`

const IntroTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 110%;
  /* identical to box height, or 31px */

  letter-spacing: -0.03em;

  @media (max-width: 600px) {
    font-size: 24px;
    max-width: 340px;
  }

  /* Grey/700 */

  color: #4a4b51;
`

const AboutTitle = styled(H3Responsive)`
  color: black;
  font-size: 62px;

  @media (max-width: 1000px) {
    text-align: center;
    font-size: 52px;
  }

  @media (max-width: 600px) {
    font-size: 48px;
  }
`

const AboutSubtitle = styled.div`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  text-align: center;

  color: #080a0c;

  @media (max-width: 1000px) {
    text-align: center;
  }
`

const RecentlySoldContainer = styled.div`
  width: 100%;
  background-color: #f8ffc0;
  border-radius: 20px;
`

const RecentlySoldTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 36px 52px 0px 52px;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

const RecentlySoldTitle = styled.div`
  font-size: 32px;
  font-weight: 700;

  @media (max-width: 600px) {
    text-align: center;
  }
`

const RecentlySoldRow = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 36px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`

const ArtworkBox = styled.div`
  width: ${DEFAULT_IMAGE_CONTAINER_WIDTH}px;
  height: ${DEFAULT_IMAGE_CONTAINER_HEIGHT}px;
  background-color: white;
  color: black;
  text-decoration: none;
  margin-right: 12px;
  margin-left: 12px;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  flex: 0 0 auto;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition-duration: 200ms;

  @media (max-width: 600px) {
    margin-bottom: 24px;
  }

  :hover {
    opacity: 0.8;
    transition-duration: 200ms;
    cursor: pointer;
  }
`

const ArtworkBoxImageOuter = styled.div`
  height: ${DEFAULT_IMAGE_CONTAINER_WIDTH}px;
  width: ${DEFAULT_IMAGE_CONTAINER_WIDTH}px;
  position: relative;
`

const ArtworkBoxImage = styled(Image)`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`

const ArtworkBoxBottom = styled.div`
  padding: 24px;
`

const ArtworkBoxTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`

const ArtworkBoxUser = styled.div`
  display: flex;
  align-items: center;
`

const ArtworkBoxUserImage = styled(Image)`
  border-radius: 100px;
`

const ArtworkBoxUsername = styled.div`
  font-weight: 600;
`

const JoinConversationText = styled.div`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  width: 100%;
`

const AboutWhiteBox = styled.div`
  position: relative;
  background-color: #f8ffc0;
  border-radius: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: 460px 1fr;
  padding: 80px;
  z-index: 2;

  @media (min-width: 1200px) {
    height: 660px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-top: -2vw;
    padding: 42px 32px;
    height: 940px;
  }

  @media (max-width: 600px) {
    padding: 32px;
    height: 660px;
  }
`

const AboutBoxSplatMain = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 100;
`

const AboutBoxSplatContainer1 = styled.div`
  position: absolute;
  right: 647px;
  top: 92px;
  z-index: 101;

  @media (max-width: 1200px) {
    display: none;
  }
`

const AboutBoxSplatContainer2 = styled.div`
  position: absolute;
  right: 97px;
  top: 110px;
  z-index: 101;

  @media (max-width: 1200px) {
    display: none;
  }
`

const AboutBoxSplatContainer3 = styled.div`
  position: absolute;
  right: 500px;
  top: 34px;
  z-index: 101;

  @media (max-width: 1200px) {
    display: none;
  }
`

const WhiteBoxLeft = styled.div`
  z-index: 10;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const WhiteBoxCheckerboard = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  // background-color: white;

  @media (max-width: 1200px) {
    top: 440px;
    left: calc(50% - 228px);
  }

  @media (max-width: 600px) {
    top: 356px;
    left: calc(50% - 130px);
  }
`

const WhiteBoxCheckerboardImageContainer = styled.div`
  width: 456px;
  height: 456px;
  position: relative;

  @media (max-width: 600px) {
    width: 260px;
    height: 260px;
  }
`

const WhiteBoxGobbler = styled.div`
  position: absolute;
  right: 106px;
  bottom: 0px;

  @media (max-width: 1200px) {
    left: calc(50% - 260px);
  }

  @media (max-width: 600px) {
    left: calc(50% - 154px);
  }
`

const WhiteBoxButtons = styled.div`
  position: absolute;
  right: 740px;
  top: 130px;

  @media (max-width: 1200px) {
    margin-top: 360px;
    left: calc(50% - 300px);
  }

  // TODO: Dynamic sized buttons
  @media (max-width: 600px) {
    top: 40px;
    left: calc(50% - 178px);
  }
`

const WhiteBoxGobblerInner = styled.div`
  width: 700px;
  height: 440px;
  position: relative;

  @media (max-width: 1200px) {
    width: 500px;
    height: 306px;
  }

  @media (max-width: 600px) {
    width: 300px;
    height: 190px;
  }
`

const WhiteBoxTitle = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 110%;
  /* max-width: 360px; */

  letter-spacing: -0.03em;

  color: #080a0c;

  @media (max-width: 1200px) {
    text-align: center;
    min-height: 70px;
  }

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const WhiteBoxSubtitle = styled.div`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  color: rgba(0, 0, 0, 0.64);
  max-width: 440px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    max-width: 300px;
  }
`

const SummonGobblersBox = styled.div`
  background-color: white;
  border-radius: 40px;
  width: 100%;
  padding: 80px;
  z-index: 2;
  position: relative;
  overflow: hidden;
  height: 660px;

  @media (max-width: 600px) {
    padding: 32px;
  }
`

const SummonGobblersTop = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
`

const SummonGobblersTitle = styled.div`
  font-size: 46px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 38px;
  }
`

const SummonGobblersSubtitle = styled.div`
  font-size: 24px;
  opacity: 0.6;
  font-weight: 500;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    max-width: 260px;
  }
`

const SummonGobblersBottom = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  bottom: -16px;
  left: 0px;
  justify-content: center;
  display: flex;
`

const SummonGobblersBottomImageContainer = styled.div`
  position: relative;
  width: 1170px;
  height: 260px;

  @media (max-width: 500px) {
    width: 500px;
    height: 312px;
  }
`

const SummonGobblersBottomImage = styled(Image)``

const SummonGobblersGradient = styled.div`
  position: absolute;
  width: 1701.7px;
  height: 587.1px;

  background: linear-gradient(180deg, #6bf1fd 0%, #96ff94 92.6%);
  opacity: 0.7;
  filter: blur(50px);
  transform: matrix(0.97, -0.05, -0.24, -0.97, 0, 0);
`

const Gradient2 = styled.div`
  position: absolute;
  width: 1200px;
  height: 600px;
  margin-top: 60px;
  background: linear-gradient(180deg, #6bf1fd 0%, #96ff94 100%);
  opacity: 0.5;
  filter: blur(104px);
  transform: translate3d(0, 0, 0), matrix(1, 0, 0, -1, 0, 0);
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
  z-index: 0;
  left: 0px;
`

const BoxRow1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 26px;
  row-gap: 26px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`

const ProduceGooBox = styled.div`
  background-image: url(/images/main-goo-bg-1.png);
  background-repeat: no-repeat;
  background-size: 640px 640px;
  border-radius: 40px;
  width: 100%;
  z-index: 2;
  height: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    background-size: 100% 640px;
    padding: 14px;
  }

  @media (max-width: 500px) {
    padding: 14px;
  }
`

const GooProductionContiainer = styled.div`
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 430px;
  width: min(530px, 100%);
  box-shadow: 0px 18.6207px 46.5517px rgba(0, 0, 0, 0.06),
    0px 9.31034px 15.5172px rgba(0, 0, 0, 0.05), 0px 3.10345px 6.2069px rgba(0, 0, 0, 0.02);

  @media (max-width: 1000px) {
    max-width: 322px;
  }
`

const GooProductionContainerTop = styled.div`
  background: rgba(255, 255, 255, 0.4);
  text-align: left;
  width: 100%;
  background-color: #bbf881;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 8px 12px 8px 12px;
  box-shadow: inset 0px 0.775862px 0.775862px rgba(255, 255, 255, 0.64);
`

const GooProductionContainerBottom = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    height: 48px;
  }
`

// const GooProductionContainerBottom = styled.div``

// const GooProductionContainerBottom = styled.div``

const ProduceGooTitle = styled.div`
  font-size: 36px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 28px;
  }
`

const ProduceGooSubtitle = styled.div`
  font-size: 18px;
  color: #4a4b51;
  font-weight: 400;
  text-align: center;
  max-width: 400px;
  line-height: 130%;

  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 300px;
  }
`

const CollectArtBox = styled.div`
  background-color: #f8ffc0;
  border-radius: 40px;
  width: 100%;
  z-index: 2;
  position: relative;
  overflow: hidden;
  height: 640px;
  display: inline-block;

  @media (max-width: 1000px) {
    display: none;
  }
`

const CollectArtTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const CollectArtImageBody = styled.div`
  width: 580px;
  position: relative;
  height: 480px;
  top: -20px;

  @media (max-width: 1200px) {
    width: 500px;
    height: 400px;
    top: -50px;
  }
`

const CollectArtImage = styled(Image)``

const CollectArtTitle = styled.div`
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-top: -32px;

  @media (max-width: 600px) {
    font-size: 28px;
  }
`

const CollectArtSubtitle = styled.div`
  font-size: 18px;
  opacity: 0.7;
  font-weight: 400;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const BoxRow2 = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  column-gap: 26px;
  row-gap: 26px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const SummonLeaderBox = styled.div`
  background: #15151e;

  overflow: hidden;
  border-radius: 40px;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;

  height: 640px;

  @media (max-width: 1000px) {
    height: 740px;
  }

  @media (max-width: 500px) {
    height: 580px;
  }
`

const SummonLeaderTop = styled.div`
  width: 100%;
  height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SummonLeaderBottom = styled.div`
  width: 100%;
  height: 278px;

  @media (max-width: 1000px) {
    height: 200px;
  }

  @media (max-width: 600px) {
  }
`

const SummonLeaderText = styled.div`
  color: white;
`

const SummonLeaderTitle = styled.div`
  font-size: 36px;
  font-weight: 700;
  text-align: center;

  color: white;
  @media (max-width: 600px) {
    font-size: 28px;
  }
`

const SummonLeaderImageContainer = styled.div`
  position: relative;
  width: 400px;
  height: 400px;

  @media (max-width: 600px) {
    width: 360px;
    height: 360px;
  }
`

const SummonLeaderImage = styled(Image)``

const GithubBox = styled.div`
  background-color: #2f3035;
  position: relative;
  border-radius: 40px;
  width: 100%;
  padding: 56px;
  z-index: 2;
  height: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 14px;
    height: 580px;
  }
`

const GithubBoxTitle = styled.div`
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: white;
`

const GithubBoxSubtitle = styled.div`
  font-size: 18px;
  color: #9c9da8;
  font-weight: 400;
  line-height: 130%;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 18px;
    max-width: 480px;
  }
`

const GithubDocsText = styled.div`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: white;
  transition-duration: 200ms;

  :hover {
    cursor: pointer;
    transition-duration: 200ms;
    opacity: 0.8;
  }
`

function GooSplatSvg1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="42" fill="none" viewBox="0 0 64 42">
      <path
        fill="#34CC34"
        stroke="#3D8E3A"
        strokeWidth="3"
        d="M9.985 35.642l-.583-.324a6.717 6.717 0 01.32-11.911l4.343-2.115a11.654 11.654 0 016.818-1.05l4.475.667a5.814 5.814 0 013.328 9.786l-4.412 4.575a9.78 9.78 0 01-11.789 1.76l-2.5-1.388z"
      ></path>
      <path
        fill="#96FF94"
        d="M19.396 29.078l1.16-.029a2.007 2.007 0 011.73 3.101l-.42.644a3.082 3.082 0 01-1.966 1.338l-.962.196a4.378 4.378 0 01-2.364-.172l-1.568-.567a1.124 1.124 0 01-.303-1.95l1.488-1.145.681-.523a4.314 4.314 0 012.524-.893z"
      ></path>
      <path
        fill="#34CC34"
        stroke="#3D8E3A"
        strokeWidth="3"
        d="M45.11 6.841l.393-.47a4.483 4.483 0 017.91 2.546l.02.274c.032.43.123.854.27 1.26l.702 1.943a5.345 5.345 0 01-2.316 6.423l-.232.137a4.466 4.466 0 01-6.55-2.587l-.427-1.447a6.32 6.32 0 00-.476-1.166c-1.18-2.227-.911-4.978.706-6.912z"
      ></path>
      <path
        fill="#96FF94"
        d="M48.362 13.752l.008-.107a1.657 1.657 0 012.269-1.425l.19.076a1.897 1.897 0 01.944 2.7l-.463.81a.982.982 0 01-1.527.228l-.607-.575-.234-.222a1.87 1.87 0 01-.58-1.485z"
      ></path>
    </svg>
  )
}

function GooSplatSvg2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="94"
      fill="none"
      viewBox="0 0 161 94"
    >
      <path
        fill="#34CC34"
        stroke="#3D8E3A"
        strokeWidth="3"
        d="M129.605 90.258l-3.559.548a9.028 9.028 0 01-10.066-6.481c-1.39-4.947 1.624-10.055 6.626-11.231l3.536-.831a12.509 12.509 0 004.368-1.97l2.637-1.868a16.488 16.488 0 0121.436 2.046l1.765 1.842a10.14 10.14 0 01-2.251 15.796l-2.669 1.541a15.48 15.48 0 01-10.279 1.864l-7.403-1.23a13.1 13.1 0 00-4.141-.026z"
      ></path>
      <path
        fill="#96FF94"
        d="M144.247 76.235l3.322.24a3.743 3.743 0 012.377 6.377l-1.494 1.493a6.317 6.317 0 01-3.959 1.83l-3.336.268a9.386 9.386 0 01-4.465-.735l-3.76-1.62a2.055 2.055 0 01-.123-3.716l3.966-2.03 1.902-.975a10.56 10.56 0 015.57-1.132z"
      ></path>
      <path
        fill="#34CC34"
        stroke="#3D8E3A"
        strokeWidth="3"
        d="M3.132 8.52l.21-.562a9.227 9.227 0 0114.37-4.005l.895.708a10.561 10.561 0 01.753 15.905l-3.754 3.598a5.465 5.465 0 01-8.514-1.213l-2.326-4.029-.898-1.555a10.409 10.409 0 01-.736-8.847z"
      ></path>
      <path
        fill="#96FF94"
        d="M7.107 9.4l.065-.174a2.881 2.881 0 014.488-1.251l.279.22a3.298 3.298 0 01.235 4.968l-1.172 1.123a1.707 1.707 0 01-2.658-.378l-.727-1.259-.28-.485a3.25 3.25 0 01-.23-2.763z"
      ></path>
    </svg>
  )
}

function GooSplatSvg3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="71" height="40" fill="none" viewBox="0 0 71 40">
      <path
        fill="#34CC34"
        stroke="#3D8E3A"
        strokeWidth="3"
        d="M34.91 19.114l-.368.994a9.832 9.832 0 009.306 13.242l2.799-.025a13.073 13.073 0 006.036-1.535l4.448-2.371a14.942 14.942 0 005.956-5.792l1.9-3.338a7.972 7.972 0 00-8.635-11.73l-4.87 1.068-4.868.47a13.905 13.905 0 00-11.704 9.017z"
      ></path>
      <path
        fill="#96FF94"
        d="M50.728 22.464L56.8 19.77c2.42-1.073 2.97-4.263 1.05-6.085a3.711 3.711 0 00-3.047-.986l-8.397 1.125a8.674 8.674 0 00-5.336 2.84l-.951 1.072a4.703 4.703 0 00-1.182 2.965l-.032.952a2.491 2.491 0 002.433 2.574l1.257.028c.447.01.893-.033 1.33-.13l5.25-1.154a8.147 8.147 0 001.552-.508z"
      ></path>
      <path
        fill="#34CC34"
        stroke="#3D8E3A"
        strokeWidth="3"
        d="M4.6 27.165l-.356.366a3.741 3.741 0 002.78 6.354l.23-.006c.359-.01.719.029 1.068.117l1.672.419a4.46 4.46 0 005.138-2.464l.094-.204a3.727 3.727 0 00-2.7-5.22l-1.237-.233a5.273 5.273 0 01-1.008-.297c-1.95-.792-4.21-.337-5.68 1.168z"
      ></path>
      <path
        fill="#96FF94"
        d="M8.703 30.78l.089-.058a1.642 1.642 0 00.116-2.653l-.158-.127a1.88 1.88 0 00-2.788.51l-.473.795a.973.973 0 00.553 1.427l.792.242.305.093c.53.16 1.103.077 1.564-.228z"
      ></path>
    </svg>
  )
}

function GithubBoxBackgroundTopSvg() {
  return (
    <div style={{ right: 0, top: 100, position: 'absolute' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="318"
        height="74"
        fill="none"
        viewBox="0 0 318 74"
      >
        <g filter="url(#filter0_d_2200_14854)">
          <path stroke="#000" strokeWidth="3" d="M380 69.754h-92.733L219.514 2H0"></path>
        </g>
        <path stroke="url(#paint0_linear_2200_14854)" strokeWidth="3" d="M76.48 2h125.926"></path>
        <defs>
          <filter
            id="filter0_d_2200_14854"
            width="382"
            height="72.753"
            x="0"
            y="0.501"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dx="1" dy="1"></feOffset>
            <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"></feColorMatrix>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2200_14854"></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_2200_14854"
              result="shape"
            ></feBlend>
          </filter>
          <linearGradient
            id="paint0_linear_2200_14854"
            x1="81.956"
            x2="202.406"
            y1="2"
            y2="2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#83FF56" stopOpacity="0"></stop>
            <stop offset="0.755" stopColor="#83FF56"></stop>
            <stop offset="1" stopColor="#83FF56" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

function GithubBoxBackgroundBottomSvg() {
  return (
    <div style={{ left: 0, bottom: 100, position: 'absolute' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="320"
        height="74"
        fill="none"
        viewBox="0 0 320 74"
      >
        <g filter="url(#filter0_d_2200_14849)">
          <path stroke="#000" strokeWidth="3" d="M-62 2.246h92.733L98.486 70H318"></path>
        </g>
        <path stroke="url(#paint0_linear_2200_14849)" strokeWidth="3" d="M241.52 70H115.594"></path>
        <defs>
          <filter
            id="filter0_d_2200_14849"
            width="382"
            height="72.753"
            x="-62"
            y="0.746"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dx="1" dy="1"></feOffset>
            <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"></feColorMatrix>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2200_14849"></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_2200_14849"
              result="shape"
            ></feBlend>
          </filter>
          <linearGradient
            id="paint0_linear_2200_14849"
            x1="236.045"
            x2="115.594"
            y1="70"
            y2="70"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#83FF56" stopOpacity="0"></stop>
            <stop offset="0.755" stopColor="#83FF56"></stop>
            <stop offset="1" stopColor="#83FF56" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

function GithubIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" fill="none" viewBox="0 0 33 32">
      <g clipPath="url(#clip0_2156_15544)">
        <path
          fill="#8EF42E"
          fillRule="evenodd"
          d="M16.5.398c-8.8 0-16 7.2-16 16a15.982 15.982 0 0010.933 15.2c.8.134 1.067-.4 1.067-.8v-2.666c-4.4.933-5.333-2.134-5.333-2.134-.667-1.866-1.734-2.4-1.734-2.4-1.6-.933 0-.933 0-.933 1.6.133 2.4 1.6 2.4 1.6 1.467 2.4 3.734 1.733 4.667 1.333.133-1.066.533-1.733 1.067-2.133-3.6-.4-7.334-1.733-7.334-7.867 0-1.733.667-3.2 1.6-4.266 0-.534-.666-2.134.267-4.267 0 0 1.333-.4 4.4 1.6 1.333-.4 2.667-.533 4-.533 1.333 0 2.667.133 4 .533 3.067-2.133 4.4-1.6 4.4-1.6.933 2.267.267 3.867.133 4.267 1.067 1.066 1.6 2.533 1.6 4.266 0 6.134-3.733 7.467-7.333 7.867.533.533 1.067 1.467 1.067 2.933v4.4c0 .4.266.934 1.066.8a15.982 15.982 0 0010.934-15.2c.133-8.8-7.067-16-15.867-16z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2156_15544">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

function SeeAllButtonIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" fill="none" viewBox="0 0 11 16">
      <path
        fill="#fff"
        d="M3.785.38A1 1 0 002.293.294l-2 2a1 1 0 000 1.414L4.586 8 .293 12.293a1 1 0 000 1.414l2 2a1 1 0 001.414 0l7-7a1 1 0 000-1.417C8.117 4.726 4.187.816 3.785.38z"
      ></path>
    </svg>
  )
}
